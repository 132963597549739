import PremiumModal from '~/components/Modals/Premium.vue'
import CreditsUpgradeModal from '~/components/Credits/UpgradeModal.vue'
import modalsData from '~/assets/modals/modals.json'

const modalComponents = {
  PremiumModal,
  CreditsUpgradeModal,
}

export const useModals = () => {
  const { $bus } = useNuxtApp()
  const { userProfile } = useKeycloak()
  const subscriptionApi = useSubscriptions()

  const freeTrial = ref(false)

  const setTrialStatus = trial => {
    freeTrial.value = trial
    console.log(freeTrial.value)
  }

  // Function to replace placeholders in strings
  const replacePlaceholders = text => {
    if (!text) return text
    return text.replace(
      '{{Transkribus}}',
      '<span style="font-family: \'Lobster Two\'">Transkribus</span>'
    )
  }

  const openModal = modal => {
    let props = {}
    modal.style?.forEach(style => {
      props[style] = true
    })
    if (modal.modalContent?.props?.type === 'primary') {
      props['customHeader'] = '--revert'
    }

    props['dismissible'] = modal.dismissible

    $bus.emit('open-modal', { modal: modal, props: props })
  }

  const createModal = params => {
    if (freeTrial.value) {
      params.modalName = 'CreditsUpgradeModal'
      params.style = ['isExtraLarge']
    }

    const defaults = {
      open: function () {
        openModal(this)
      },
    }
    let modal = { ...defaults, ...params }

    if (!modal.modalName || !modalComponents[modal.modalName]) {
      console.error(`Component ${modal.modalName} not found in modalComponents`)
    }

    modal.modalContent.component = modalComponents[modal.modalName]
    // Replace placeholders in text fields
    if (modal.modalContent?.props?.heading) {
      modal.modalContent.props.heading = replacePlaceholders(
        modal.modalContent.props.heading
      )
    }
    return modal
  }

  const getModals = async (trialStatus: boolean) => {
    await setTrialStatus(trialStatus)
    const modals = modalsData.map(createModal) // Process modals.json
    return modals
  }

  return {
    getModals,
  }
}
