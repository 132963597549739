<template>
    <div class="app-modal__box">
        <div class="app-modal__header">
            <h3 class="text-primary font-semibold mb-2" v-html="$t(props.heading)"></h3>
            <p class="text-[#4D4D4D]">
                {{ props.description }}
            </p>
        </div>
        <div class="app-modal__body">
            <div class="flex flex-col items-center">
                <div class="mb-8">
                    <div class="flex justify-end relative z-50 top-3 -right-9">
                        <BaseBadge rounded :type="'custom'" :backgroundColor="'#3AB131'" :textColor="'#EAF8E8'"
                            :label="'Save 20%'"></BaseBadge>
                    </div>
                    <BaseButtonSwitch class="z-10">
                        <template v-slot:buttons>
                            <BaseButton :label="$t('Monthly billing')" :active="timeToggle" @click="timeToggle = true">
                            </BaseButton>
                            <BaseButton :label="$t('Annual billing')" :active="!timeToggle" @click="timeToggle = false">
                            </BaseButton>
                        </template>
                    </BaseButtonSwitch>
                </div>
                <div id="creditsSlider" class="w-1/2 md:w-1/5 mb-6">
                    <VueSlider v-if="timeToggle" v-model="moSliderValue" :adsorb="true" :interval="1" :min="0" :max="3"
                        :data="['150', '300', '600']" :dot-size="16" :dot-style="{
                            backgroundColor: '#FFF',
                            border: 'solid 3px #193060',
                        }" :process-style="{
                        backgroundColor: '#193060',
                    }" :tooltip="'always'" :tooltip-placement="'bottom'" :tooltip-formatter="'{value} credits /mo'"
                        :tooltip-style="{
                            backgroundColor: '#193060',
                            color: '#FFF',
                            fontFamily: 'Noto Sans',
                            fontSize: '12px',
                            padding: '8px',
                        }" :tooltip-class="'slider-tooltip'" :lazy="true" />
                    <VueSlider v-else-if="!timeToggle" v-model="yrSliderValue" :adsorb="true" :interval="1" :min="0"
                        :max="6" :data="['900', '1500', '3000', '10000', '25000', '50000']" :dot-size="16" :dot-style="{
                            backgroundColor: '#FFF',
                            border: 'solid 3px #193060',
                        }" :process-style="{
                        backgroundColor: '#193060',
                    }" :tooltip="'always'" :tooltip-placement="'bottom'" :tooltip-formatter="'{value} credits /yr'"
                        :tooltip-style="{
                            backgroundColor: '#193060',
                            color: '#FFF',
                            fontFamily: 'Noto Sans',
                            fontSize: '12px',
                            padding: '8px',
                        }" :tooltip-class="'slider-tooltip'" :lazy="true" />
                </div>
            </div>

            <!-- lrg+ screens -->
            <CreditsUpgradeCards class="hidden lg:block" :tiers="tiersData[0].tierCards"
                :sliderValue="timeToggle ? moSliderValue : yrSliderValue" :toggle="timeToggle" />
            <Disclosure class="hidden lg:block" as="div" v-slot="{ open }">
                <DisclosureButton class="flex w-full py-6 items-center justify-center font-semibold text-primary">
                    <div class="flex-grow border-t border-gray-100"></div>
                    <div class="flex items-center mx-4">
                        <div class="pr-2">{{ $t('Compare plans') }}</div>
                        <ChevronRightIcon :class="[
                            open ? 'rotate-90' : '',
                            'size-5 shrink-0',
                        ]" aria-hidden="true" />
                    </div>
                    <div class="flex-grow border-t border-gray-100"></div>
                </DisclosureButton>
                <transition enter-active-class="transition-all ease-in-out duration-500 delay-[200ms]"
                    enter-from-class="opacity-0 translate-y-6" enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition-all ease-in-out duration-300" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <DisclosurePanel as="div">
                        <CreditsUpgradeTable :tiers="tiersData[0].tierCards" :sections="tiersData[0].tierTbl"
                            :sliderValue="timeToggle ? moSliderValue : yrSliderValue" :toggle="timeToggle" />
                    </DisclosurePanel>
                </transition>
            </Disclosure>

            <!-- smaller screens -->
            <CreditsUpgradeTable class="lg:hidden" :tiers="tiersData[0].tierCards" :sections="tiersData[0].tierTbl"
                :sliderValue="timeToggle ? moSliderValue : yrSliderValue" :toggle="timeToggle" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

import { ref, computed, watch } from 'vue'
import VueSlider from 'vue-3-slider-component'
import tiersJsonData from '~/assets/modals/upgrade-flow.json'

const tiersData = ref(tiersJsonData)
const timeToggle = ref(true)

const moSliderValue = ref('150')
const yrSliderValue = ref('900')

const props = withDefaults(
  defineProps<{
    heading?: string,
    description?: string,
  }>(),
  {
    heading: 'Compare our plans and find yours',
    description: 'Simple, transparent pricing that grows with you. Try any plan for free.',
    toggle: true,
  }
)

// onMounted(() => {
//   console.log(tiersData.value[0])
// })
// watch(tiersData, (newValue, oldValue) => {
//   console.log(newValue)
// })

</script>
<style scoped>
#creditsSlider :deep(.vue-slider-dot-tooltip-inner-bottom:after) {
    border-bottom-color: #193060 !important;
}
</style>