<template>
        <div class="mx-auto max-w-7xl">
            <!-- smaller screens -->
            <div class="mx-auto max-w-md space-y-8 sm:mt-16 lg:hidden">
                <section v-for="tier in props.tiers" :key="tier.id" :class="[
                    tier.mostPopular
                        ? 'bg-upgrade/5 ring-1 ring-inset ring-upgrade'
                        : 'ring-1 ring-inset ring-gray-100',
                    'rounded-lg p-8',
                ]">
                    <h3 :id="tier.id" class="text-base font-semibold text-gray-500">
                        {{ tier.name }}
                    </h3>
                    <p v-if="toggle" class="mt-2 flex items-baseline gap-x-1">
                        <span class="text-2xl font-semibold text-primary">{{ getCost(tier.moPrices) }}</span>
                        <span class="text-sm text-gray-300">{{ getCost(tier.moPrices) !== 'Custom' ? 'per month' : '' }}</span>
                    </p>
                    <p v-else class="mt-2 flex items-baseline gap-x-1">
                        <span class="text-2xl font-semibold text-primary">{{ getCost(tier.yrPrices) }}</span>
                        <span class="text-sm text-gray-300">{{ getCost(tier.yrPrices) !== 'Custom' ? 'per year' : '' }}</span>
                    </p>
                    <a :href="getLink(tier)" :aria-describedby="tier.id" :class="[
                        tier.mostPopular
                            ? 'bg-upgrade hover:bg-upgrade/85'
                            : 'bg-primary hover:bg-primaryG-300',
                        'text-white mt-8 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryG-600',
                    ]">                                        
                        {{ tier.linkMsg }}
                    </a>
                    <ul role="list" class="mt-10 space-y-4 text-sm/6 text-gray-500">
                        <li v-for="section in props.sections" :key="section.name">
                            <ul role="list" class="space-y-4">
                                <template v-for="feature in section.features" :key="feature.name">
                                    <li v-if="feature.tiers[tier.name]" class="flex gap-x-3">
                                        <CheckIcon class="h-6 w-5 flex-none text-primaryG-600" aria-hidden="true" />
                                        <span>
                                            {{ feature.name }}
                                            {{ ' ' }}
                                            <span v-if="typeof feature.tiers[tier.name] === 'string'"
                                                class="text-sm/6 text-gray-500">({{ feature.tiers[tier.name] }})</span>
                                        </span>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </ul>
                </section>
            </div>

            <!-- lrg+ -->
            <div class="isolate hidden lg:block">
                <div>
                    <table class="w-full table-fixed text-left">
                        <caption class="sr-only">
                            Pricing plan comparison
                        </caption>
                        <colgroup>
                            <col class="w-1/4" />
                            <col class="w-1/4" />
                            <col class="w-1/4" />
                            <col class="w-1/4" />
                        </colgroup>
                        <thead>
                            <tr>
                                <td />
                                <th v-for="tier in tiers" :key="tier.id" scope="col" class="px-4">
                                    <div class="text-base font-semibold text-primary">
                                        {{ tier.name }}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"><span class="sr-only">Price</span></th>
                                <td v-for="tier in tiers" :key="tier.id" class="px-4 pt-2">
                                    <div v-if="toggle" class="flex items-baseline gap-x-1">
                                        <span class="text-2xl font-semibold text-primary">{{
                                            getCost(tier.moPrices)
                                            }}</span>
                                        <span class="text-sm/6 text-gray-300">{{ getCost(tier.moPrices) !== 'Custom' ? 'per month' : '' }}</span>
                                    </div>
                                    <div v-else class="flex items-baseline gap-x-1">
                                        <span class="text-2xl font-semibold text-primary">{{
                                            getCost(tier.yrPrices)
                                            }}</span>
                                        <span class="text-sm/6 text-gray-300">{{ getCost(tier.yrPrices) !== 'Custom' ? 'per year' : '' }}</span>
                                    </div>
                                    <a :href="getLink(tier)" class="mt-4 block rounded py-1 text-center text-sm/6 font-semibold text-white bg-primary hover:bg-upgrade hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryG-600 transition-all duration-300 ease-in-out">
                                        {{ tier.linkMsg }}
                                    </a>
                                </td>
                            </tr>
                            <template v-for="(section, sectionIdx) in props.sections" :key="section.name">
                                <tr>
                                    <th scope="colgroup" colspan="4" class="pt-10 pb-4 px-4 text-base font-semibold text-primary">
                                        {{ section.name }}
                                    </th>
                                </tr>
                                <tr v-for="(feature, index) in section.features" :key="feature.name">
                                    <th scope="row" :class="{'bg-white': index % 2}" class="bg-[#FAFAFA] px-4 py-1.5 text-sm/6 font-normal text-gray-500">
                                        {{ feature.name }}
                                    </th>
                                    <td v-for="tier in tiers" :key="tier.id" :class="{'bg-white': index % 2}" class="bg-[#FAFAFA] px-6 py-2 xl:px-8">
                                        <div v-if="typeof feature.tiers[tier.name] === 'string'"
                                            class="text-center text-sm/6 text-secondaryG-700">
                                            {{ feature.tiers[tier.name] }}
                                        </div>
                                        <template v-else>
                                            <li v-if="feature.tiers[tier.name] === true" class="flex justify-center">
                                                <span class="material-symbols-outlined--outline text-primary">check_circle</span>
                                            </li>
                                            <!-- <MinusIcon v-else class="mx-auto size-5 text-gray-400" aria-hidden="true" /> -->
                                            <span class="sr-only">{{
                                                feature.tiers[tier.name] === true
                                                    ? 'Included'
                                                : 'Not included'
                                                }}
                                                in {{ tier.name }}</span>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
</template>

<script lang="ts" setup>
import { CheckIcon, MinusIcon } from '@heroicons/vue/20/solid'

const props = withDefaults(
  defineProps<{
    tiers?: Array,
    sections?: Array,
    sliderValue?: string,
    toggle?: boolean,
  }>(),
  {
    tiers: [],
    sections: [],
    sliderValue: '150',
    toggle: true,
  }
)

const getCost = (cost: Record<string, string>) => {
  return cost && cost[props.sliderValue] !== undefined ? cost[props.sliderValue] : 'Custom';
}

const getLink = (tier) => {
    const pidList = props.toggle ? tier.moPckgIds : tier.yrPckgIds
  return pidList && pidList[props.sliderValue] !== undefined ? `https://my.readcoop.org/payment?pid=${pidList[props.sliderValue]}&fsid=${tier.bundleId}` : "https://info.transkribus.org/contact-sales"
}
</script>
