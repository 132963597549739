<template>
    <div class="mx-auto max-w-7xl">
        <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div v-for="tier in props.tiers" :key="tier.id" class="group rounded-lg p-4 xl:p-5 ring-1 ring-gray-100 hover:ring-2 hover:ring-upgrade transition-all duration-300 ease-in-out">
                <div class="flex flex-col items-center">
                    <div class="bg-gray-50 rounded-lg w-12 h-12 mb-6 flex flex-col items-center justify-center">
                        <span class="material-symbols-outlined--extralarge text-primary group-hover:text-upgrade transition-all duration-300 ease-in-out">{{ tier.icon }}</span>
                    </div>
                    <div class="flex items-center justify-between gap-x-4">
                        <h3 :id="tier.id" class="!mb-3 text-2xl font-bold text-primary group-hover:text-upgrade transition-all duration-300 ease-in-out">
                            {{ tier.name }}
                        </h3>
                    </div>
                    <p class="flex items-baseline">
                        <span v-if="toggle" class="text-xl tracking-tight text-primary">
                            {{ getCost(tier.moPrices) }}
                        </span>
                        <span v-else class="text-xl tracking-tight text-primary">
                            {{ getCost(tier.yrPrices) }}
                        </span>
                    </p>
                    <p class="!mb-0 text-sm/6 text-secondaryG-700 group-hover:text-primary">{{ tier.description }}</p>
                </div>
                <ul role="list" class="mt-6 space-y-2 text-sm/6 text-primary">
                    <li v-for="feature in tier.features" :key="feature" class="flex items-center gap-x-2">
                        <span class="material-symbols-outlined--outline">check_circle</span>
                        {{ feature }}
                    </li>
                </ul>
                <a :href="getLink(tier)" :aria-describedby="tier.id" class="mt-6 block rounded p-2 text-center text-sm/6 font-medium text-white bg-primary hover:bg-upgrade/85 group-hover:bg-upgrade group-hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryG-600 transition-all duration-300 ease-in-out">
                    {{ tier.linkMsg }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    tiers?: Array,
    sliderValue?: string,
    toggle?: boolean,
  }>(),
  {
    tiers: [],
    sliderValue: '150',
    toggle: true,
  }
)


// watch(() => props.sliderValue, (first, second) => {
//       console.log(
//         props.tiers[0].moPrices[props.sliderValue]
//       );
//     });

const getCost = (cost: Record<string, string>) => {
  return cost && cost[props.sliderValue] !== undefined ? `${cost[props.sliderValue]}/mo` : 'Custom'
}

const getLink = (tier) => {
    const pidList = props.toggle ? tier.moPckgIds : tier.yrPckgIds
  return pidList && pidList[props.sliderValue] !== undefined ? `https://my.readcoop.org/payment?pid=${pidList[props.sliderValue]}&fsid=${tier.bundleId}` : "https://info.transkribus.org/contact-sales"
}

</script>
